.basket {
  display: flex;
  padding: 40px 60px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &_container {
    max-width: 1128px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    overflow: hidden;

    &_title {
      font-size: 30px;
      font-weight: 300;
      line-height: 36.31px;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }

    &_info {
      overflow: auto;
      table {
        border-collapse: collapse;
        width: 100%;

        thead {
          background-color: #DEE2E6;

          tr {
            th {
              padding: 24px 0;
            }
          }
        }

        tbody {
          border: 2px solid #D9D9D9;

          tr {
            border-bottom: 2px solid #D9D9D9;

            &:hover {
              border: 3px solid #b5b5b5;
            }

            td {
              padding: 10px 0 3px 0;
              min-width: 120px;
            }
          }

          .basketImg {
            width: 90px;
            height: 90px;
            @media(max-width:750px){
              width: 100px;
              height: 100px;
            }
            
          }
        }

        tfoot {
          background-color: #DEE2E6;

          tr {
            th {
              button {
                border: none;
                color: white;
                background-color: black;
                width: 100%;
                height: 100%;
                padding: 24px 0;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}