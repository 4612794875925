.log_reg {
  position: fixed;
  top: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  .registration {
    position: absolute;
    background-color: #ffffffec;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 35px 50px;
    transform: translate(-50%, -50%);

    @media (max-width:1200px) {
      top: 0;
    }

    &_close {
      position: absolute;
      top: 15px;
      right: 15px;

      img {
        cursor: pointer;
      }
    }

    &_title {
      p {
        font-size: 20px;
        font-weight: 400;
        line-height: 24.2px;
        letter-spacing: 0.14em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }
    }

    &_subTitle {

      p,
      span {
        font-size: 14px;
        font-weight: 500;
        line-height: 16.94px;
        letter-spacing: 0.14em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #606060;
        text-wrap: nowrap;

      }

      span {
        color: black;
        cursor: pointer;

      }
    }

    &_input {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 15px;

      &_item {
        display: flex;
        // justify-content: space-between;
        flex-direction: column;
        gap: 5px;
        width: 100%;

        small {
          color: red;
        }

        input,
        select {
          width: 260px;
          padding: 12px 20px;
          border: none;
          outline: none;
          box-shadow: inset 0px 2px 5px rgba(0, 0, 0, 0.3);
        }

        select {
          color: #606060;
          background-position-x: calc(100% - 15px)
        }
      }

      button {
        background-color: black;
        color: white;
        border-radius: 20px;
        font-size: 16px;
        font-weight: 600;
        line-height: 19.04px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        padding: 8px 50px;
        cursor: pointer;
      }
    }
  }

  .login {
    position: absolute;
    background-color: #ffffffec;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 35px 50px;
    transform: translate(-50%, -50%);

    @media (max-width:1200px) {
      top: 0px;
    }

    &_title {
      p {
        font-size: 20px;
        font-weight: 400;
        line-height: 24.2px;
        letter-spacing: 0.14em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;

      }
    }

    &_subTitle {

      p,
      span {
        font-size: 14px;
        font-weight: 500;
        line-height: 16.94px;
        letter-spacing: 0.14em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #606060;
        text-wrap: nowrap;
        @media(max-width:450px){
          text-wrap: wrap;
        }
      }

      span {
        color: black;
        cursor: pointer;

      }
    }

    &_input {
      display: flex;
      flex-direction: column;
      gap: 15px;

      input {
        width: 260px;
        padding: 12px 20px;
        border: none;
        outline: none;
        box-shadow: inset 0px 2px 5px rgba(0, 0, 0, 0.3);
      }
    }

    &_button {
      button {
        background-color: black;
        color: white;
        border-radius: 20px;
        font-size: 16px;
        font-weight: 600;
        line-height: 19.04px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        padding: 8px 50px;
        cursor: pointer;
      }
    }

    &_close {
      position: absolute;
      top: 15px;
      right: 15px;

      img {
        cursor: pointer;
      }
    }
  }
  .succeeded_modal {
    position: absolute;
    background-color: #ffffffe9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 35px 50px;
    width: 350px;
    transform: translate(-50%, -50%);
    top: 0;
    @media (max-width:500px) {
      width: 280px;
    }

    &_title {
      p {
        font-size: 20px;
        font-weight: 400;
        line-height: 24.2px;
        letter-spacing: 0.14em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;

      }
    }

    &_subTitle {

      p,
      span {
        font-size: 14px;
        font-weight: 500;
        line-height: 16.94px;
        letter-spacing: 0.14em;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #606060;
      }

      span {
        color: black;
        cursor: pointer;

      }
    }

    &_input {
      display: flex;
      flex-direction: column;
      gap: 15px;

      input {
        width: 260px;
        padding: 12px 20px;
        border: none;
        outline: none;
        box-shadow: inset 0px 2px 5px rgba(0, 0, 0, 0.3);
      }
    }

    &_button {
      button {
        background-color: black;
        color: white;
        border-radius: 20px;
        font-size: 16px;
        font-weight: 600;
        line-height: 19.04px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        padding: 8px 50px;
        cursor: pointer;
      }
    }

    &_close {
      position: absolute;
      top: 15px;
      right: 15px;

      img {
        cursor: pointer;
      }
    }
  }
}