.header {
  width: 100%;
  box-shadow: 0px -6px 25px black;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &_burger {
    width: 1920px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 30px;
    display: none;

    a {
      font-size: 18px;
      font-weight: 400;
      line-height: 24.2px;
      letter-spacing: 0.15em;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      cursor: pointer;
      transition: transform 0.2s ease;
      text-decoration: none;
      color: black;
    }

    @media (max-width:830px) {
      display: flex;
    }
  }

  &_container {
    width: 1920px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 62px;

    @media (max-width:930px) {
      padding: 15px 30px;
    }

    @media (max-width:830px) {
      display: none;
    }

    &_logo {
      display: flex;
      align-items: center;
      gap: 120px;

      @media (max-width:1050px) {
        gap: 80px;
      }

      @media (max-width:870px) {
        gap: 50px;
      }


      &_img {
        a {
          font-size: 20px;
          font-weight: 400;
          line-height: 24.2px;
          letter-spacing: 0.15em;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          cursor: pointer;
          transition: transform 0.2s ease;

          &:hover {
            transform: translateY(-3px)
          }
        }
      }

      &_nav {
        display: flex;
        align-items: center;
        gap: 62px;

        a {
          text-wrap: nowrap;
        }

        @media (max-width:1050px) {
          gap: 30px;
        }
      }
    }



    &_cart {
      display: flex;
      align-items: center;
      gap: 72px;

      @media(max-width:1100px) {
        gap: 20px;
      }

      &_search {
        p {
          cursor: pointer;
        }

        &_box {
          position: relative;

          img {
            position: absolute;
            right: 5px;
            top: 3px;
            cursor: pointer;
          }

          input {
            padding: 5px 10px;
            border: none;
            outline: none;
            box-shadow: inset 0px 2px 5px rgba(0, 0, 0, 0.3);
          }

          &_modal {
            width: 100%;
            position: absolute;
            background-color: white;
            box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
            top: 30px;
            height: 400px;
            padding: 10px;
            gap: 5px;
            display: flex;
            flex-direction: column;
            z-index: 10;

            &_item {
              width: 100%;
              display: flex;
              flex-direction: column;
              gap: 10px;
              padding: 5px;
              align-items: start;
              cursor: pointer;
              background-color: rgb(247, 247, 247);
              border-radius: 5px;

              &:hover {
                background-color: rgb(237, 237, 237);

              }

              p {
                font-size: 14px;
              }
            }
          }
        }
      }
    }

    a {
      display: flex;
      color: black;
      text-decoration: none;
      cursor: pointer;
      font-size: 16px;
      font-weight: 400;
      line-height: 24.2px;
      letter-spacing: 0.14em;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      transition: transform 0.2s ease;

      &:hover {
        transform: translateY(-2px)
      }
    }
  }

  &_tool {
    position: fixed;
    right: 0;
    top: 200px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 40px 13px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    background-color: white;
    z-index: 5;

    &_translation {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      >img{
        width: 24px;
        height: 24px;
      }
      &_box {
        display: flex;
        gap: 15px;
        position: absolute;
        top: 0px;
        left: 50px;
        background-color: white;
        padding: 8px 15px;
        box-shadow: 0px 4px 4px 0px #00000040;
        transition: all .3s;

        img {
          cursor: pointer;
        }
      }

      &_boxActiv {
        display: flex;
        left: -120px;
        gap: 15px;
        position: absolute;
        top: 0px;
        background-color: white;
        padding: 8px 15px;
        transition: all .3s;
        box-shadow: 0px 4px 4px 0px #00000040;

        img {
          cursor: pointer;
        }
      }

    }

    img {
      cursor: pointer;
    }
  }
}