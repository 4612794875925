.accordion {
  font-family: Arial, sans-serif;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 5px;

  .headerRow {
    display: flex;
    justify-content: space-between;
    padding: 24px;
    background-color: #DEE2E6;
    font-weight: bold;
    text-transform: uppercase;
    border-bottom: 1px solid rgb(208, 208, 208);

    span {
      font-family: "Chivo", sans-serif;
      flex: 1;
      text-align: center;
    }
  }

  .order {
    border-bottom: 1px solid #000000;

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px;
      background-color: #DEE2E6;
      position: relative;
      cursor: pointer;

      span {
        font-family: "Chivo", sans-serif;
        flex: 1;
        text-align: center;
      }

      .toggleIcon {
        flex: 0.2;
        text-align: right;
        font-size: 14px;
        position: absolute;
        right: 10px;
      }

      &:hover {
        background-color: #ededed;
      }
    }

    .details {
      display: flex;
      justify-content: space-evenly;
      padding: 10px;
      background-color: #fafafa;

      .product {
        margin-bottom: 10px;
        display: flex;
        gap: 10px;

        .item {
          display: flex;
          flex-direction: column;
          align-items: start;
          justify-content: space-between;

          div {
            border-bottom: 1px solid #434343;
            width: 100%;
            height: 2px;
          }
        }

        p {
          font-family: "Chivo", sans-serif;
          font-size: 12px;
          font-weight: 400;
          line-height: 14.28px;
          text-align: center;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;

        }

        img {
          object-fit: cover;
        }
      }

      .info {
        display: flex;
        justify-content: space-between;

        div {
          width: 48%;
          text-align: left;
          display: flex;
          flex-direction: column;
          gap: 15px;

          span {
            font-family: "Chivo", sans-serif;
            font-size: 12px;
            font-weight: 600;
            line-height: 14.28px;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
          }

          p {
            font-family: "Chivo", sans-serif;
            font-size: 12px;
            font-weight: 300;
            line-height: 12px;
            letter-spacing: 0.12em;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
          }
        }
      }

      .delivery {
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 150px;
        text-align: left;
        span {
          font-family: "Chivo", sans-serif;
          font-size: 12px;
          font-weight: 600;
          line-height: 14.28px;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
        }

        p {
          font-family: "Chivo", sans-serif;
          font-size: 12px;
          font-weight: 300;
          line-height: 12px;
          letter-spacing: 0.12em;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
        }
      }
    }

    &.active .row {
      background-color: #212529;
      color: white;
    }
  }
}