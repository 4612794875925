.product_item {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;

  &_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 80px;

    &_title {
      p {
        font-family: Inter;
        font-size: 20px;
        font-weight: 400;
        line-height: 24.2px;
        letter-spacing: 0.14em;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;

      }
    }

    &_box {
      display: flex;
      gap: 50px;
      justify-content: space-around;

      @media (max-width:1200px) {
        flex-direction: column;
      }

      &_img {
        .product-page {
          display: flex;
          align-items: flex-start;
          gap: 24px;

          @media (max-width:900px) {
            flex-direction: column;

          }

          &__main-image {
            img {
              width: 370px;
              height: 390px;
              object-fit: cover;
              @media (max-width:500px) {
                width: 270px;
                height: 290px;

              }
            }
          }

          &__thumbnails {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 16px;

            @media (max-width:900px) {
              flex-direction: unset;
              justify-content: center;
              align-items: center;
            }

            img {
              width: 120px;
              height: 120px;
              object-fit: cover;
              border: 2px solid #ccc;
              cursor: pointer;
              transition: border 0.3s;

              @media (max-width:500px) {
                flex-direction: column;
                width: 80px;
                height: 80px;
              }

              &.active {
                border-color: #000;
              }

              &:hover {
                border-color: #333;
              }
            }
          }

          &__info {
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: space-between;
            gap: 20px;

            &_title {
              display: flex;
              flex-direction: column;
            }

            span {
              font-family: Inter;
              font-size: 20px;
              font-weight: 400;
              line-height: 24.2px;
              text-align: left;
              text-underline-position: from-font;
              text-decoration-skip-ink: none;
              text-wrap: nowrap;
            }

            p {
              margin: 5px 0;
            }

            &_quantity {
              display: flex;
              align-items: center;
              gap: 10px;

              button {
                width: 30px;
                height: 30px;
                font-size: 1rem;
                border: none;
                background-color: none;
                background: none;
                cursor: pointer;
              }
            }

            &_add-to-cart {
              cursor: pointer;
              margin-top: 30px;
            }
          }
        }
      }

      &_info {
        p {
          font-family: Inter;
          font-size: 22px;
          font-weight: 200;
          line-height: 26.63px;
          letter-spacing: 0.15em;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
        }
      }
    }

    &_recommend {
      display: flex;
      gap: 50px;
      flex-wrap: wrap;
    }
  }
}