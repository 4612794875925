.home {
  padding: 80px 60px 40px 60px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  @media(max-height:800px){
  padding: 40px 60px 40px 60px;

  }
  &_container {
    display: flex;
    gap: 55px;

    @media (max-width:1200px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    @media (max-width:700px) {
      gap: 20px;
    }

    @media (max-width:650px) {
      gap: 10px;
    }

    @media (max-width:600px) {
      gap: 0;
    }

    &_left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: end;

      @media (max-width:1200px) {
        gap: 20px;
      }

      @media (max-width:700px) {
        align-items: center;

      }

      &_title {
        p {
          font-size: 64px;
          font-weight: 200;
          line-height: 77.45px;
          letter-spacing: 0.15em;
          text-align: right;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;

          @media (max-width:1800px) {
            font-size: 60px;
          }

          @media (max-width:1600px) {
            font-size: 40px;
            line-height: 50px;
          }

          @media (max-width:1200px) {
            font-size: 64px;
            line-height: 77.45px;
            text-align: center;
          }

          @media (max-width:700px) {
            font-size: 40px;
            line-height: 50.45px;
            text-align: center;
          }

        }
      }

      &_img {
        width: 17.5vw;
        max-height: 404px;
        overflow: hidden;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: width 0.3s, height 0.3s;
        }

        @media (max-width:1200px) {
          width: 331px;
          height: 408px;
        }

        @media (max-width:700px) {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 47vw;
          margin: 0 auto;
          min-width: 250px;
        }

        @media (max-width:500px) {
          height: 350px;
        }
      }
    }

    &_center {
      width: 32vw;
      height: 100%;
      max-height: 655px;
      overflow: hidden;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        transition: width 0.3s, height 0.3s;
      }

      @media (max-width:1200px) {
        width: 540px;
        height: 655px;
      }

      @media (max-width:700px) {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 47vw;
        margin: 0 auto;
        max-height: 404px;
        min-width: 250px;

      }

      @media (max-width:500px) {
        height: 300px;
      }

    }

    &_right {
      display: flex;
      flex-direction: column;
      gap: 32px;

      @media (max-width:1200px) {
        gap: 20px;
      }

      &_title {
        p {
          font-size: 40px;
          font-weight: 200;
          line-height: 48.41px;
          letter-spacing: 0.15em;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;

          @media (max-width:1800px) {
            font-size: 35px;
          }

          @media (max-width:1600px) {
            font-size: 25px;
            line-height: 30px;
          }

          @media (max-width:1200px) {
            font-size: 40px;
            line-height: 48.41px;
            text-align: center;
          }

          @media (max-width:700px) {
            font-size: 30px;
            line-height: 30.45px;
            text-align: center;
          }

        }
      }

      &_img {
        width: 17.5vw;
        max-height: 404px;
        overflow: hidden;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: width 0.3s, height 0.3s;
        }

        @media (max-width:1200px) {
          width: 331px;
          height: 408px;
        }

        @media (max-width:700px) {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 47vw;
          margin: 0 auto;
          min-width: 250px;
        }

        @media (max-width:500px) {
          height: 350px;
        }
      }

    }
  }

  
}