.slider-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  text-align: center;
}

.slider-image-container {
  display: flex;
  transition: transform 0.3s ease-in-out;
}

.slide {
  transform: translateX(-100%);
}

.slider-image {
  width: 100%;
  height: auto;
  flex-shrink: 0;
  cursor: pointer;
  &:hover{
    opacity: 0.8;
  }
}

.slider-indicators {
  margin-top: 10px;
}

.indicator {
  cursor: pointer;
  padding: 5px;
  font-size: 16px;
  color: #ddd;
  transition: color 0.3s ease;
}

.indicator.active {
  font-size: 24px;
  color: #1890ff;
}
