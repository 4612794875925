.burger-menu {
  position: relative;
}

.burger-icon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 40px;
  height: 18px;
  background: transparent;
  border: none;
  cursor: pointer;
}

.burger-icon .line {
  width: 100%;
  height: 2px;
  background-color: #333;
  transition: all 0.3s ease;
}

.menu {
  position: absolute;
  top: 40px;
  right: 0;
  background-color: #fff;
  width: 200px;
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 15px, rgba(0, 0, 0, 0.23) 0px 0px 6px;
  z-index: 10;
}

.menu.open {
  height: max-content;
}

.menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu li {
  padding: 10px;
}

.menu a {
  text-decoration: none;
  color: #333;
}
