.personal_data{
  width: 100%;
  height: 100%;
  form{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 150px;
    gap: 20px;
    @media(max-width:1200px){
      gap: 20px;
    }
    >div{
      display: flex;
      gap: 12px;
      >div{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 5px;
        align-items: start;
        position: relative;
        small{
          color: red;
          font-size: 12px;
          position: absolute;
          bottom: -17px;
        }
        input{
          width: 100%;
          padding: 12px 6px;
          background-color: #F8F9FA;
          border: none;
          box-shadow: 0px 4px 4px 0px #00000040 inset;
        }
      }
    }
  }
}