.product {
  display: flex;
  padding: 40px 60px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &_container {
    width: 1280px;
    display: flex;
    flex-direction: column;
    gap: 40px;

    @media (max-width:1400px) {
      width: 1000px;
    }

    @media (max-width:1200px) {
      width: 700px;
    }

    @media (max-width:900px) {
      width: 700px;
      gap: 20px;
    }

    @media (max-width:810px) {
      width: 500px;
    }

    @media (max-width:640px) {
      width: 350px;
    }

    @media (max-width:470px) {
      width: 250px;
    }

    &_title {
      p {
        font-size: 30px;
        font-weight: 300;
        line-height: 36.31px;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }
    }

    &_list {
      display: flex;
      flex-wrap: wrap;
      gap: 60px;
      margin-top: 50px;

      @media (max-width:1200px) {
        gap: 30px;
      }

      @media (max-width:900px) {
        gap: 20px;
        justify-content: center;

      }

      &_item {
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin-bottom: 15px;
        position: relative;
        >img{
          height: 200px;
          width: 150px;
          cursor: pointer;
          transition: all .2s;
          &:hover {
            transform: translateY(-3px)
          }
        }
        &_favorite{
          position: absolute;
          right: 10px;
          top: 10px;
          
        }
        &_info {
          display: flex;
          justify-content: space-between;
          position: relative;
          flex-direction: column;
          gap: 5px;
          &_title {
            display: flex;
            justify-content: space-between;
            img{
              width: 18px;
              cursor: pointer;
            }
            p {
              font-family: -apple-system, BlinkMacSystemFont, 'Inter',
                sans-serif;
              font-family: Inder;
              font-size: 18px;
              font-weight: 400;
              line-height: 22.5px;
              text-align: left;
              text-underline-position: from-font;
              text-decoration-skip-ink: none;
              cursor: pointer;
            }
          }
          &_desc{
            display: flex;
            justify-content: space-between;
            img {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}